<template>
    <div>
        <van-nav-bar
                title="标题"
                left-text="返回"
                right-text="按钮"
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
        />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: 'home'
            }
        },
        methods: {
            onClickLeft() {
                this.$router.back()
            },
            onClickRight() {
                this.$toast('按钮');
            }
        }
    }
</script>

